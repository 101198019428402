import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <MercadoLibreLogo />
            </div>
            <h2>
                Proceso de tu comprador
            </h2>
            <h3>
                Mensajes al comprador
            </h3>
            <p>
                <b>Hay dos tipos de mensajes que los compradores reciben: </b><br />
                Cuando envias el link al momento de confirmar el pago, solo se recibe el mensaje principal. <br />
                Cuando el link se envia al momento de recibir el pedido, se reciben ambos mensajes.
            </p>
            <p>
                <b>Mensaje enviado al confirmar el pago si el link se envía al momento de recibir el producto</b>
            </p>
            <pre>
                <p>
                Hola! <br /><br />
Gracias por realizar su compra con nosotros, una vez se confirme de recibido el pedido, te haremos llegar un link para obtener tu factura si la necesitas.
                </p>
            </pre>
            <p>
                <b>Mensaje principal, el cual contiene el link de facturación.</b>
            </p>
            <pre>
                <p>
En caso de requerir factura de su pedido, puede hacer click en el enlace siguiente para obtenerla: <br /><br />

<span className="accent">Facturar pedido </span><br /><br />

Facturar es completamente opcional.
                </p>
            </pre>
            <p>
                Los mensajes no puedes ser customizados ya que el recurso utilizado para mandar los links es específico para solicitar facturación, contexto adicional podría mandar a mediación el mensaje.
            </p>
            <h3>
                Facturación en línea
            </h3>
            <p>
                El único concepto importante a saber sobre el proceso de facturación de tu cliente es <b>la forma de pago, esta siempre será 31 (Intermediaros)</b> ya que 
                es un concepto estipulado en la RMF 2018 art. 2.7.1.41. <br />
                <span className="quote small">Esta forma de pago es particular a MercadoLibre y Amazon, puedes consultar la <a href="https://www.sat.gob.mx/cs/Satellite?blobcol=urldata&blobkey=id&blobtable=MungoBlobs&blobwhere=1461172992100&ssbinary=true" target="_blank">RMF 2018 aquí</a></span>
            </p>

            <ImageFooted explanation={`Cuando un comprador factura con FiscalPOP por primera vez, deben registar su RFC, Nombre y correo sólo una vez.`}>
                <IMG_Mercadolibre file={`autofactReg`} />
            </ImageFooted>
            <p>
                El registro de los compradores se comparte entre todo MercadoLibre, por lo que no tendrán que repetir su registro.
            </p>
            <ImageFooted explanation={`La segunda vez que un comprador abre un link de facturación, verá una selección de RFCs anteriormente usados y solo tendrá que dar click a uno de ellos.`}>
                <IMG_Mercadolibre file={'autofactAgain'} />
            </ImageFooted>
            <ImageFooted explanation={`La vista para confirmar la factura es sencilla y automáticamente se enviará el PDF y XML a su correo.`}>
                <IMG_Mercadolibre file={'autofactComplete'} />
            </ImageFooted>
            <SectionNavigate next={`/ecommerce/mercadolibre/facturacion-manual`} previous={`/ecommerce/mercadolibre/config-autofact`}/>
        </div>
    </Layout>
)

export default ShopifyClavesDefault;